<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<span style="width: 0.8rem;">所属分类：</span>
					<el-select v-model="search_questionnairetktype_id" filterable clearable placeholder="请选择" @change="gettable">
						<el-option
							v-for="item in type_option"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addnew">新增题</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="150" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="editerow(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		<!-- 新增题弹窗 -->
		<el-dialog :title="isEdit?'编辑题':'新增题'" :visible.sync="dialogVisible" :before-close="resetdata" width="600px">
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="标题" prop="name">
					<div style="display: flex;align-items: center;">
						<el-input v-model="form.name"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="所属类型" prop="questionnairetktype_id">
					<el-select v-model="form.questionnairetktype_id" filterable placeholder="请选择类型">
						<el-option
							v-for="item in type_option"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="form.type">
						<el-radio :label="1">输入框</el-radio>
						<el-radio :label="2">单选</el-radio>
						<el-radio :label="3">多选</el-radio>
						<el-radio :label="4">文本域</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="最大选择数量" v-if="form.type == 3">
					<div style="display: flex;align-items: center;">
						<el-input v-model="form.max" type="number" placeholder="空为不限制"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="选项数据" v-if="form.type == 2 || form.type == 3" prop="options">
					<div style="display: flex;flex-direction: column;max-height: 40vh;overflow-y: auto;">
						<div v-for="(option, index) in form.options" :key="index" class="option-item">
							<el-input v-model="option.value" placeholder="请输入选项"></el-input>
							<el-checkbox v-model="option.hasInput">包含输入框</el-checkbox>
							<el-button @click="removeOption(index)" type="danger" icon="el-icon-delete" style="margin-left: 10px;"></el-button>
						</div>
						<div style="display: flex;align-items: center;justify-content: center;">
							<el-button @click="addOption" type="primary" icon="el-icon-plus">新增选项</el-button>
						</div>
					</div>
				</el-form-item>
				
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="resetdata">取消</el-button>
				<el-button type="primary" @click="submitForm('form')">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				search_questionnairetktype_id:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '问题名称',
						width: ''
					},
					{
						prop: 'type',
						name: '问题类型',
						type: 'wt_types',
						width: ''
					},
					{
						prop: 'questionnairetktype_name',
						name: '所属分类',
						width: ''
					}
				],
				tableData: [],
				dialogVisible: false,
				form: {
					id: null,
					name: '',
					type: 1,
					max: null,
					options: [{
						value: '',
						hasInput: false
					}, {
						value: '',
						hasInput: false
					}],
					questionnairetktype_id: null
				},
				isEdit: false,
				rules: {
					name: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
					options: [{
						validator: (rule, value, callback) => {
							if (this.form.type == 2 || this.form.type == 3) {
								if (value.length < 2) {
									callback(new Error('至少需要两个选项'));
								} else if (value.some(option => option.value.trim() === '')) {
									callback(new Error('选项内容不能为空'));
								} else {
									callback();
								}
							} else {
								callback();
							}
						},
						trigger: 'change'
					}],
					questionnairetktype_id: [{
						required: true,
						message: '请选择所属类型',
						trigger: 'change'
					}]
				},
				type_option: []
			}
		},
		mounted() {
			this.gettable()
			this.$post({
				url: '/api/questionnairetktype/index',
				params: {
					name: '',
					p: 1,
					size: 9999
				}
			}).then((res) => {
				this.type_option = res.list
			})
		},
		methods: {
			resetdata() {
				this.form = {
					id: null,
					name: '',
					type: 1,
					max: null,
					options: [{
						value: '',
						hasInput: false
					}, {
						value: '',
						hasInput: false
					}],
					questionnairetktype_id: null
				}
				this.dialogVisible = false
				this.isEdit = false
			},
			editerow(row) {
				this.isEdit = true;
				row.xx.forEach((item) => {
					if (item.hasInput) {
						item.hasInput = true
					} else {
						item.hasInput = false
					}
				})
				this.form = {
					...row,
					options: row.xx,
					questionnairetktype_id: row.questionnairetktype_id
				}
				this.dialogVisible = true;
			},
			deleterow(row) {
				this.$confirm('请确认是否删除该问题?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/questionnairetk/del',
						params: {
							ids: row.id
						}
					}).then((res) => {
						this.$message.success('删除成功');
						this.gettable()
					})
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/questionnairetk/index',
					params: {
						name: this.searchinput,
						questionnairetktype_id:this.search_questionnairetktype_id,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.dialogVisible = true;
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
			addOption() {
				this.form.options.push({
					value: '',
					hasInput: false
				});
			},
			removeOption(index) {
				if (this.form.options.length > 2) {
					this.form.options.splice(index, 1);
				} else {
					this.$message.error('至少需要两个选项');
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let dataobj = JSON.parse(JSON.stringify(this.form))
						dataobj.xx = dataobj.options
						delete dataobj.options

						if (this.isEdit) {
							this.$post({
								url: '/api/questionnairetk/edit',
								params: dataobj
							}).then((res) => {
								this.$message.success('编辑成功');
								this.gettable()
								this.resetdata()
							})
						} else {
							this.$post({
								url: '/api/questionnairetk/add',
								params: dataobj
							}).then((res) => {
								this.$message.success('添加成功');
								this.gettable()
								this.resetdata()
							})
						}

					} else {
						this.$message.error('请检查表单');
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import 'list.scss';

	.option-item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.option-item .el-input {
		flex: 1;
		margin-right: 10px;
	}
</style>
